/**
 * Created by gschulz on 13.04.17.
 */

let EfbHelperUnitSwitch = function () {

    let _private = {};

    this.switchFields = function (fields, unit, format) {

        let unitType  = MathConvertUnit.unitTypeMap[unit];
        let unitLabel = MathConvertUnit.unitDefinition[unitType]['UNITS'][unit].label;
        fields.each(function (i, field) {
            field = $(field);
            if (!_private.isCompatibleUnit(field.data('unit-type'), unitType)) {
                console.log(field.data('unit-type'), unitType);
                return;
            }
            let value     = field.val();
            let baseValue = field.uVal();
            let labelKey  = field.data('label-key');
            let label     = field.prev('label');

            // set the new unit
            field.data('unit-view', unit).attr('data-unit-view', unit);
            if (format) {
                field.data('format', format).attr('data-format', format);
            } else {
                field.removeData('format');
                field.removeAttr('data-format');
            }
            label.text(EfbHelper._(labelKey).replace(':unit:', unitLabel));
            if(value !== ''){
                field.uVal(baseValue);
            }
        });


    };

    _private.isCompatibleUnit = function (fieldType, newType) {
        if (fieldType.toUpperCase() === newType.toUpperCase()) {
            return true;
        }
        if (fieldType.toUpperCase() === 'FUEL' && (newType === 'VOLUME' || newType === 'WEIGHT')) {
            return true;
        }
        //incompatible
        return false;
    };
};